enum Actions {
  // action types
  ADD_BODY_CLASSNAME = "addBodyClassName",
  REMOVE_BODY_CLASSNAME = "removeBodyClassName",
  ADD_BODY_ATTRIBUTE = "addBodyAttribute",
  REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
  ADD_CLASSNAME = "addClassName",
  VERIFY_AUTH = "verifyAuth",
  LOGIN = "login",
  LOGOUT = "logout",
  REGISTER = "register",
  UPDATE_USER = "updateUser",
  FORGOT_PASSWORD = "forgotPassword",
  SET_BREADCRUMB_ACTION = "setBreadcrumbAction",
  SET_THEME_MODE_ACTION = "setThemeModeAction",

  LIST_ZONES = "listZones",
  GET_ZONES = "getZones",

  LIST_ENVIRONMENTS = "listEnvironments",
  GET_ENVIRONMENTS = "getEnvironments",

  LIST_UNITS = "listUnits",
  GET_UNITS = "getUnits",

  LIST_UNIT_CONFIGURATIONS = "listUnitConfigurations",
  GET_UNIT_CONFIGURATIONS = "getUnitConfigurations",

  LIST_UNIT_STATUSES = "listUnitStatuses",
  GET_UNIT_STATUSES = "getUnitStatuses",

  LIST_INTERACTORS = "listInteractors",
  GET_INTERACTORS = "getInteractors",

  LIST_MEASURES = "listMeasures",
  GET_MEASURES = "getMeasures",

  LIST_SYSTEMS = "listSystems",
  GET_SYSTEMS = "getSystems",

  LIST_SENSORS = "listSensors",
  GET_SENSORS = "getSensors",

  LIST_USER_INFOS = "listUserInfos",
  GET_USER_INFOS = "getUserInfos",

  LIST_TASKS = "listTasks",
  GET_TASKS = "getTasks",

  LIST_MESSAGES = "listMessages",
  GET_MESSAGES = "getMessages",

  LIST_COMMANDS = "listCommands",
  GET_COMMANDS = "getCommands",
}

enum Mutations {
  // mutation types
  SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
  PURGE_AUTH = "logOut",
  SET_AUTH = "setAuth",
  SET_USER = "setUser",
  SET_USER_INFOS = "setUserInfos",
  SET_PASSWORD = "setPassword",
  SET_ERROR = "setError",
  SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
  SET_LAYOUT_CONFIG = "setLayoutConfig",
  SET_BUILDER_CONFIG = "setBuilderConfig",
  RESET_LAYOUT_CONFIG = "resetLayoutConfig",
  OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
  OVERRIDE_BUILDER_CONFIG = "overrideLayoutConfig",
  OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",
  SET_THEME_MODE_MUTATION = "setThemeModeMutation",

  SET_INTERACTORS = "setInteractors",
  UPDATE_INTERACTOR = "updateInteractor",
  CREATE_INTERACTOR = "createInteractor",
  DELETE_INTERACTOR = "deleteInteractor",

  SET_MEASURES = "setMeasures",
  UPDATE_MEASURE = "updateMeasure",
  CREATE_MEASURE = "createMeasure",
  DELETE_MEASURE = "deleteMeasure",

  SET_ZONES = "setZones",
  UPDATE_ZONE = "updateZone",
  CREATE_ZONE = "createZone",
  DELETE_ZONE = "deleteZone",

  SET_ENVIRONMENTS = "setEnvironments",
  UPDATE_ENVIRONMENT = "updateEnvironment",
  CREATE_ENVIRONMENT = "createEnvironment",
  DELETE_ENVIRONMENT = "deleteEnvironment",

  SET_UNITS = "setUnits",
  UPDATE_UNIT = "updateUnit",
  CREATE_UNIT = "createUnit",
  DELETE_UNIT = "deletUnit",

  SET_UNIT_CONFIGURATIONS = "setUnitsConfigurations",
  UPDATE_UNIT_CONFIGURATION = "updateUnitConfiguration",
  CREATE_UNIT_CONFIGURATION = "createUnitConfiguration",
  DELETE_UNIT_CONFIGURATION = "deletUnitConfiguration",

  SET_UNIT_STATUSES = "setUnitsStatuses",
  UPDATE_UNIT_STATUS = "updateUnitStatus",
  CREATE_UNIT_STATUS = "createUnitStatus",
  DELETE_UNIT_STATUS = "deletUnitStatus",

  SET_SYSTEMS = "setSystems",
  UPDATE_SYSTEM = "updateSystem",
  CREATE_SYSTEM = "createSystem",
  DELETE_SYSTEM = "deleteSystem",

  SET_SENSORS = "setSensors",
  UPDATE_SENSOR = "updateSensor",
  CREATE_SENSOR = "createSensor",
  DELETE_SENSOR = "deleteSensor",

  SET_MESSAGES = "setMessages",
  UPDATE_MESSAGE = "updateMessage",
  CREATE_MESSAGE = "createMessage",
  DELETE_MESSAGE = "deleteMessage",

  SET_TASKS = "setTasks",
  UPDATE_TASK = "updateTask",
  CREATE_TASK = "createTask",
  DELETE_TASK = "deleteTask",

  SET_COMMANDS = "setCommands",
  UPDATE_COMMAND = "updateCommand",
  CREATE_COMMAND = "createCommand",
  DELETE_COMMAND = "deleteCommand",

  BUILD_FIRMWARE = "buildFirmware",
}

export { Actions, Mutations };
