/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUserInfo = /* GraphQL */ `
  mutation CreateUserInfo(
    $input: CreateUserInfoInput!
    $condition: ModelUserInfoConditionInput
  ) {
    createUserInfo(input: $input, condition: $condition) {
      id
      username
      firstName
      lastName
      email
      role
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUserInfo = /* GraphQL */ `
  mutation UpdateUserInfo(
    $input: UpdateUserInfoInput!
    $condition: ModelUserInfoConditionInput
  ) {
    updateUserInfo(input: $input, condition: $condition) {
      id
      username
      firstName
      lastName
      email
      role
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUserInfo = /* GraphQL */ `
  mutation DeleteUserInfo(
    $input: DeleteUserInfoInput!
    $condition: ModelUserInfoConditionInput
  ) {
    deleteUserInfo(input: $input, condition: $condition) {
      id
      username
      firstName
      lastName
      email
      role
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createZone = /* GraphQL */ `
  mutation CreateZone(
    $input: CreateZoneInput!
    $condition: ModelZoneConditionInput
  ) {
    createZone(input: $input, condition: $condition) {
      id
      name
      description
      environments {
        items {
          id
          ZoneId
          zone {
            id
            name
            description
            owner
            createdAt
            updatedAt
            __typename
          }
          name
          description
          unit {
            nextToken
            __typename
          }
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateZone = /* GraphQL */ `
  mutation UpdateZone(
    $input: UpdateZoneInput!
    $condition: ModelZoneConditionInput
  ) {
    updateZone(input: $input, condition: $condition) {
      id
      name
      description
      environments {
        items {
          id
          ZoneId
          zone {
            id
            name
            description
            owner
            createdAt
            updatedAt
            __typename
          }
          name
          description
          unit {
            nextToken
            __typename
          }
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteZone = /* GraphQL */ `
  mutation DeleteZone(
    $input: DeleteZoneInput!
    $condition: ModelZoneConditionInput
  ) {
    deleteZone(input: $input, condition: $condition) {
      id
      name
      description
      environments {
        items {
          id
          ZoneId
          zone {
            id
            name
            description
            owner
            createdAt
            updatedAt
            __typename
          }
          name
          description
          unit {
            nextToken
            __typename
          }
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createEnvironment = /* GraphQL */ `
  mutation CreateEnvironment(
    $input: CreateEnvironmentInput!
    $condition: ModelEnvironmentConditionInput
  ) {
    createEnvironment(input: $input, condition: $condition) {
      id
      ZoneId
      zone {
        id
        name
        description
        environments {
          items {
            id
            ZoneId
            name
            description
            owner
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        owner
        createdAt
        updatedAt
        __typename
      }
      name
      description
      unit {
        items {
          id
          EnvironmentId
          environment {
            id
            ZoneId
            name
            description
            owner
            createdAt
            updatedAt
            __typename
          }
          name
          shortId
          description
          configuration {
            id
            UnitId
            firmware
            usb_port
            usb_baud_rate
            server_listen
            server_send
            server_baud_rate
            async
            wifi
            network
            password
            board
            shield
            bluetooth
            radio
            number_of_groups
            createdAt
            updatedAt
            __typename
          }
          pinout {
            id
            UnitId
            s
            i
            m
            createdAt
            updatedAt
            __typename
          }
          status {
            id
            UnitId
            obs
            createdAt
            updatedAt
            __typename
          }
          historic {
            nextToken
            __typename
          }
          commands {
            nextToken
            __typename
          }
          cycle {
            id
            name
            description
            duration
            recursive
            daily
            hourly
            lightCycle
            Sunset
            Sunrise
            createdAt
            updatedAt
            __typename
          }
          profile {
            id
            name
            description
            loop
            loopLast
            createdAt
            updatedAt
            __typename
          }
          cycles
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateEnvironment = /* GraphQL */ `
  mutation UpdateEnvironment(
    $input: UpdateEnvironmentInput!
    $condition: ModelEnvironmentConditionInput
  ) {
    updateEnvironment(input: $input, condition: $condition) {
      id
      ZoneId
      zone {
        id
        name
        description
        environments {
          items {
            id
            ZoneId
            name
            description
            owner
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        owner
        createdAt
        updatedAt
        __typename
      }
      name
      description
      unit {
        items {
          id
          EnvironmentId
          environment {
            id
            ZoneId
            name
            description
            owner
            createdAt
            updatedAt
            __typename
          }
          name
          shortId
          description
          configuration {
            id
            UnitId
            firmware
            usb_port
            usb_baud_rate
            server_listen
            server_send
            server_baud_rate
            async
            wifi
            network
            password
            board
            shield
            bluetooth
            radio
            number_of_groups
            createdAt
            updatedAt
            __typename
          }
          pinout {
            id
            UnitId
            s
            i
            m
            createdAt
            updatedAt
            __typename
          }
          status {
            id
            UnitId
            obs
            createdAt
            updatedAt
            __typename
          }
          historic {
            nextToken
            __typename
          }
          commands {
            nextToken
            __typename
          }
          cycle {
            id
            name
            description
            duration
            recursive
            daily
            hourly
            lightCycle
            Sunset
            Sunrise
            createdAt
            updatedAt
            __typename
          }
          profile {
            id
            name
            description
            loop
            loopLast
            createdAt
            updatedAt
            __typename
          }
          cycles
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteEnvironment = /* GraphQL */ `
  mutation DeleteEnvironment(
    $input: DeleteEnvironmentInput!
    $condition: ModelEnvironmentConditionInput
  ) {
    deleteEnvironment(input: $input, condition: $condition) {
      id
      ZoneId
      zone {
        id
        name
        description
        environments {
          items {
            id
            ZoneId
            name
            description
            owner
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        owner
        createdAt
        updatedAt
        __typename
      }
      name
      description
      unit {
        items {
          id
          EnvironmentId
          environment {
            id
            ZoneId
            name
            description
            owner
            createdAt
            updatedAt
            __typename
          }
          name
          shortId
          description
          configuration {
            id
            UnitId
            firmware
            usb_port
            usb_baud_rate
            server_listen
            server_send
            server_baud_rate
            async
            wifi
            network
            password
            board
            shield
            bluetooth
            radio
            number_of_groups
            createdAt
            updatedAt
            __typename
          }
          pinout {
            id
            UnitId
            s
            i
            m
            createdAt
            updatedAt
            __typename
          }
          status {
            id
            UnitId
            obs
            createdAt
            updatedAt
            __typename
          }
          historic {
            nextToken
            __typename
          }
          commands {
            nextToken
            __typename
          }
          cycle {
            id
            name
            description
            duration
            recursive
            daily
            hourly
            lightCycle
            Sunset
            Sunrise
            createdAt
            updatedAt
            __typename
          }
          profile {
            id
            name
            description
            loop
            loopLast
            createdAt
            updatedAt
            __typename
          }
          cycles
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUnitStatus = /* GraphQL */ `
  mutation CreateUnitStatus(
    $input: CreateUnitStatusInput!
    $condition: ModelUnitStatusConditionInput
  ) {
    createUnitStatus(input: $input, condition: $condition) {
      id
      UnitId
      obs
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUnitStatus = /* GraphQL */ `
  mutation UpdateUnitStatus(
    $input: UpdateUnitStatusInput!
    $condition: ModelUnitStatusConditionInput
  ) {
    updateUnitStatus(input: $input, condition: $condition) {
      id
      UnitId
      obs
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUnitStatus = /* GraphQL */ `
  mutation DeleteUnitStatus(
    $input: DeleteUnitStatusInput!
    $condition: ModelUnitStatusConditionInput
  ) {
    deleteUnitStatus(input: $input, condition: $condition) {
      id
      UnitId
      obs
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUnit = /* GraphQL */ `
  mutation CreateUnit(
    $input: CreateUnitInput!
    $condition: ModelUnitConditionInput
  ) {
    createUnit(input: $input, condition: $condition) {
      id
      EnvironmentId
      environment {
        id
        ZoneId
        zone {
          id
          name
          description
          environments {
            nextToken
            __typename
          }
          owner
          createdAt
          updatedAt
          __typename
        }
        name
        description
        unit {
          items {
            id
            EnvironmentId
            name
            shortId
            description
            cycles
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        owner
        createdAt
        updatedAt
        __typename
      }
      name
      shortId
      description
      configuration {
        id
        UnitId
        firmware
        usb_port
        usb_baud_rate
        server_listen
        server_send
        server_baud_rate
        async
        wifi
        network
        password
        board
        shield
        bluetooth
        radio
        number_of_groups
        groups {
          sensors {
            i
            __typename
          }
          interactors {
            i
            __typename
          }
          systems {
            i
            __typename
          }
          modules {
            i
            __typename
          }
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      pinout {
        id
        UnitId
        s
        i
        m
        createdAt
        updatedAt
        __typename
      }
      status {
        id
        UnitId
        obs
        createdAt
        updatedAt
        __typename
      }
      historic {
        items {
          id
          unitID
          obs
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      commands {
        items {
          id
          unitID
          Command
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cycle {
        id
        name
        description
        duration
        recursive
        daily
        hourly
        lightCycle
        Sunset
        Sunrise
        createdAt
        updatedAt
        __typename
      }
      profile {
        id
        name
        description
        cycles {
          id
          name
          description
          duration
          recursive
          daily
          hourly
          lightCycle
          Sunset
          Sunrise
          createdAt
          updatedAt
          __typename
        }
        loop
        loopLast
        createdAt
        updatedAt
        __typename
      }
      cycles
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUnit = /* GraphQL */ `
  mutation UpdateUnit(
    $input: UpdateUnitInput!
    $condition: ModelUnitConditionInput
  ) {
    updateUnit(input: $input, condition: $condition) {
      id
      EnvironmentId
      environment {
        id
        ZoneId
        zone {
          id
          name
          description
          environments {
            nextToken
            __typename
          }
          owner
          createdAt
          updatedAt
          __typename
        }
        name
        description
        unit {
          items {
            id
            EnvironmentId
            name
            shortId
            description
            cycles
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        owner
        createdAt
        updatedAt
        __typename
      }
      name
      shortId
      description
      configuration {
        id
        UnitId
        firmware
        usb_port
        usb_baud_rate
        server_listen
        server_send
        server_baud_rate
        async
        wifi
        network
        password
        board
        shield
        bluetooth
        radio
        number_of_groups
        groups {
          sensors {
            i
            __typename
          }
          interactors {
            i
            __typename
          }
          systems {
            i
            __typename
          }
          modules {
            i
            __typename
          }
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      pinout {
        id
        UnitId
        s
        i
        m
        createdAt
        updatedAt
        __typename
      }
      status {
        id
        UnitId
        obs
        createdAt
        updatedAt
        __typename
      }
      historic {
        items {
          id
          unitID
          obs
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      commands {
        items {
          id
          unitID
          Command
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cycle {
        id
        name
        description
        duration
        recursive
        daily
        hourly
        lightCycle
        Sunset
        Sunrise
        createdAt
        updatedAt
        __typename
      }
      profile {
        id
        name
        description
        cycles {
          id
          name
          description
          duration
          recursive
          daily
          hourly
          lightCycle
          Sunset
          Sunrise
          createdAt
          updatedAt
          __typename
        }
        loop
        loopLast
        createdAt
        updatedAt
        __typename
      }
      cycles
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUnit = /* GraphQL */ `
  mutation DeleteUnit(
    $input: DeleteUnitInput!
    $condition: ModelUnitConditionInput
  ) {
    deleteUnit(input: $input, condition: $condition) {
      id
      EnvironmentId
      environment {
        id
        ZoneId
        zone {
          id
          name
          description
          environments {
            nextToken
            __typename
          }
          owner
          createdAt
          updatedAt
          __typename
        }
        name
        description
        unit {
          items {
            id
            EnvironmentId
            name
            shortId
            description
            cycles
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        owner
        createdAt
        updatedAt
        __typename
      }
      name
      shortId
      description
      configuration {
        id
        UnitId
        firmware
        usb_port
        usb_baud_rate
        server_listen
        server_send
        server_baud_rate
        async
        wifi
        network
        password
        board
        shield
        bluetooth
        radio
        number_of_groups
        groups {
          sensors {
            i
            __typename
          }
          interactors {
            i
            __typename
          }
          systems {
            i
            __typename
          }
          modules {
            i
            __typename
          }
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      pinout {
        id
        UnitId
        s
        i
        m
        createdAt
        updatedAt
        __typename
      }
      status {
        id
        UnitId
        obs
        createdAt
        updatedAt
        __typename
      }
      historic {
        items {
          id
          unitID
          obs
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      commands {
        items {
          id
          unitID
          Command
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cycle {
        id
        name
        description
        duration
        recursive
        daily
        hourly
        lightCycle
        Sunset
        Sunrise
        createdAt
        updatedAt
        __typename
      }
      profile {
        id
        name
        description
        cycles {
          id
          name
          description
          duration
          recursive
          daily
          hourly
          lightCycle
          Sunset
          Sunrise
          createdAt
          updatedAt
          __typename
        }
        loop
        loopLast
        createdAt
        updatedAt
        __typename
      }
      cycles
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCommand = /* GraphQL */ `
  mutation CreateCommand(
    $input: CreateCommandInput!
    $condition: ModelCommandConditionInput
  ) {
    createCommand(input: $input, condition: $condition) {
      id
      unitID
      Command
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCommand = /* GraphQL */ `
  mutation UpdateCommand(
    $input: UpdateCommandInput!
    $condition: ModelCommandConditionInput
  ) {
    updateCommand(input: $input, condition: $condition) {
      id
      unitID
      Command
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCommand = /* GraphQL */ `
  mutation DeleteCommand(
    $input: DeleteCommandInput!
    $condition: ModelCommandConditionInput
  ) {
    deleteCommand(input: $input, condition: $condition) {
      id
      unitID
      Command
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createHistoric = /* GraphQL */ `
  mutation CreateHistoric(
    $input: CreateHistoricInput!
    $condition: ModelHistoricConditionInput
  ) {
    createHistoric(input: $input, condition: $condition) {
      id
      unitID
      obs
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateHistoric = /* GraphQL */ `
  mutation UpdateHistoric(
    $input: UpdateHistoricInput!
    $condition: ModelHistoricConditionInput
  ) {
    updateHistoric(input: $input, condition: $condition) {
      id
      unitID
      obs
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteHistoric = /* GraphQL */ `
  mutation DeleteHistoric(
    $input: DeleteHistoricInput!
    $condition: ModelHistoricConditionInput
  ) {
    deleteHistoric(input: $input, condition: $condition) {
      id
      unitID
      obs
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUnitConfiguration = /* GraphQL */ `
  mutation CreateUnitConfiguration(
    $input: CreateUnitConfigurationInput!
    $condition: ModelUnitConfigurationConditionInput
  ) {
    createUnitConfiguration(input: $input, condition: $condition) {
      id
      UnitId
      firmware
      usb_port
      usb_baud_rate
      server_listen
      server_send
      server_baud_rate
      async
      wifi
      network
      password
      board
      shield
      bluetooth
      radio
      number_of_groups
      groups {
        sensors {
          i
          p {
            v
            __typename
          }
          __typename
        }
        interactors {
          i
          p {
            v
            __typename
          }
          __typename
        }
        systems {
          i
          p {
            v
            __typename
          }
          __typename
        }
        modules {
          i
          p {
            v
            __typename
          }
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUnitConfiguration = /* GraphQL */ `
  mutation UpdateUnitConfiguration(
    $input: UpdateUnitConfigurationInput!
    $condition: ModelUnitConfigurationConditionInput
  ) {
    updateUnitConfiguration(input: $input, condition: $condition) {
      id
      UnitId
      firmware
      usb_port
      usb_baud_rate
      server_listen
      server_send
      server_baud_rate
      async
      wifi
      network
      password
      board
      shield
      bluetooth
      radio
      number_of_groups
      groups {
        sensors {
          i
          p {
            v
            __typename
          }
          __typename
        }
        interactors {
          i
          p {
            v
            __typename
          }
          __typename
        }
        systems {
          i
          p {
            v
            __typename
          }
          __typename
        }
        modules {
          i
          p {
            v
            __typename
          }
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUnitConfiguration = /* GraphQL */ `
  mutation DeleteUnitConfiguration(
    $input: DeleteUnitConfigurationInput!
    $condition: ModelUnitConfigurationConditionInput
  ) {
    deleteUnitConfiguration(input: $input, condition: $condition) {
      id
      UnitId
      firmware
      usb_port
      usb_baud_rate
      server_listen
      server_send
      server_baud_rate
      async
      wifi
      network
      password
      board
      shield
      bluetooth
      radio
      number_of_groups
      groups {
        sensors {
          i
          p {
            v
            __typename
          }
          __typename
        }
        interactors {
          i
          p {
            v
            __typename
          }
          __typename
        }
        systems {
          i
          p {
            v
            __typename
          }
          __typename
        }
        modules {
          i
          p {
            v
            __typename
          }
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUnitPinout = /* GraphQL */ `
  mutation CreateUnitPinout(
    $input: CreateUnitPinoutInput!
    $condition: ModelUnitPinoutConditionInput
  ) {
    createUnitPinout(input: $input, condition: $condition) {
      id
      UnitId
      s
      i
      m
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUnitPinout = /* GraphQL */ `
  mutation UpdateUnitPinout(
    $input: UpdateUnitPinoutInput!
    $condition: ModelUnitPinoutConditionInput
  ) {
    updateUnitPinout(input: $input, condition: $condition) {
      id
      UnitId
      s
      i
      m
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUnitPinout = /* GraphQL */ `
  mutation DeleteUnitPinout(
    $input: DeleteUnitPinoutInput!
    $condition: ModelUnitPinoutConditionInput
  ) {
    deleteUnitPinout(input: $input, condition: $condition) {
      id
      UnitId
      s
      i
      m
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createInteractor = /* GraphQL */ `
  mutation CreateInteractor(
    $input: CreateInteractorInput!
    $condition: ModelInteractorConditionInput
  ) {
    createInteractor(input: $input, condition: $condition) {
      id
      name
      shortId
      description
      pins
      commands
      type
      category
      allowMultiple
      variables
      bootScript
      printScript
      stateScript
      commandsScript
      functionsScript
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateInteractor = /* GraphQL */ `
  mutation UpdateInteractor(
    $input: UpdateInteractorInput!
    $condition: ModelInteractorConditionInput
  ) {
    updateInteractor(input: $input, condition: $condition) {
      id
      name
      shortId
      description
      pins
      commands
      type
      category
      allowMultiple
      variables
      bootScript
      printScript
      stateScript
      commandsScript
      functionsScript
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteInteractor = /* GraphQL */ `
  mutation DeleteInteractor(
    $input: DeleteInteractorInput!
    $condition: ModelInteractorConditionInput
  ) {
    deleteInteractor(input: $input, condition: $condition) {
      id
      name
      shortId
      description
      pins
      commands
      type
      category
      allowMultiple
      variables
      bootScript
      printScript
      stateScript
      commandsScript
      functionsScript
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createSensor = /* GraphQL */ `
  mutation CreateSensor(
    $input: CreateSensorInput!
    $condition: ModelSensorConditionInput
  ) {
    createSensor(input: $input, condition: $condition) {
      id
      name
      shortId
      description
      powerSupply
      sensingPeriod
      allowMultiple
      manufactor
      variables
      bootScript
      printScript
      stateScript
      commandsScript
      functionsScript
      category
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateSensor = /* GraphQL */ `
  mutation UpdateSensor(
    $input: UpdateSensorInput!
    $condition: ModelSensorConditionInput
  ) {
    updateSensor(input: $input, condition: $condition) {
      id
      name
      shortId
      description
      powerSupply
      sensingPeriod
      allowMultiple
      manufactor
      variables
      bootScript
      printScript
      stateScript
      commandsScript
      functionsScript
      category
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteSensor = /* GraphQL */ `
  mutation DeleteSensor(
    $input: DeleteSensorInput!
    $condition: ModelSensorConditionInput
  ) {
    deleteSensor(input: $input, condition: $condition) {
      id
      name
      shortId
      description
      powerSupply
      sensingPeriod
      allowMultiple
      manufactor
      variables
      bootScript
      printScript
      stateScript
      commandsScript
      functionsScript
      category
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createSystem = /* GraphQL */ `
  mutation CreateSystem(
    $input: CreateSystemInput!
    $condition: ModelSystemConditionInput
  ) {
    createSystem(input: $input, condition: $condition) {
      id
      name
      shortId
      description
      variables
      bootScript
      printScript
      stateScript
      commandsScript
      functionsScript
      communicationScript
      category
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateSystem = /* GraphQL */ `
  mutation UpdateSystem(
    $input: UpdateSystemInput!
    $condition: ModelSystemConditionInput
  ) {
    updateSystem(input: $input, condition: $condition) {
      id
      name
      shortId
      description
      variables
      bootScript
      printScript
      stateScript
      commandsScript
      functionsScript
      communicationScript
      category
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteSystem = /* GraphQL */ `
  mutation DeleteSystem(
    $input: DeleteSystemInput!
    $condition: ModelSystemConditionInput
  ) {
    deleteSystem(input: $input, condition: $condition) {
      id
      name
      shortId
      description
      variables
      bootScript
      printScript
      stateScript
      commandsScript
      functionsScript
      communicationScript
      category
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createSeedBatch = /* GraphQL */ `
  mutation CreateSeedBatch(
    $input: CreateSeedBatchInput!
    $condition: ModelSeedBatchConditionInput
  ) {
    createSeedBatch(input: $input, condition: $condition) {
      id
      batch
      date
      grower
      description
      numberOfSeeds
      germinationRate
      seedLife
      taxonomy
      variety
      commonName
      pollination
      quality
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateSeedBatch = /* GraphQL */ `
  mutation UpdateSeedBatch(
    $input: UpdateSeedBatchInput!
    $condition: ModelSeedBatchConditionInput
  ) {
    updateSeedBatch(input: $input, condition: $condition) {
      id
      batch
      date
      grower
      description
      numberOfSeeds
      germinationRate
      seedLife
      taxonomy
      variety
      commonName
      pollination
      quality
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteSeedBatch = /* GraphQL */ `
  mutation DeleteSeedBatch(
    $input: DeleteSeedBatchInput!
    $condition: ModelSeedBatchConditionInput
  ) {
    deleteSeedBatch(input: $input, condition: $condition) {
      id
      batch
      date
      grower
      description
      numberOfSeeds
      germinationRate
      seedLife
      taxonomy
      variety
      commonName
      pollination
      quality
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createProfile = /* GraphQL */ `
  mutation CreateProfile(
    $input: CreateProfileInput!
    $condition: ModelProfileConditionInput
  ) {
    createProfile(input: $input, condition: $condition) {
      id
      name
      description
      cycles {
        id
        name
        description
        duration
        recursive
        daily
        hourly
        lightCycle
        Sunset
        Sunrise
        createdAt
        updatedAt
        __typename
      }
      loop
      loopLast
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateProfile = /* GraphQL */ `
  mutation UpdateProfile(
    $input: UpdateProfileInput!
    $condition: ModelProfileConditionInput
  ) {
    updateProfile(input: $input, condition: $condition) {
      id
      name
      description
      cycles {
        id
        name
        description
        duration
        recursive
        daily
        hourly
        lightCycle
        Sunset
        Sunrise
        createdAt
        updatedAt
        __typename
      }
      loop
      loopLast
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteProfile = /* GraphQL */ `
  mutation DeleteProfile(
    $input: DeleteProfileInput!
    $condition: ModelProfileConditionInput
  ) {
    deleteProfile(input: $input, condition: $condition) {
      id
      name
      description
      cycles {
        id
        name
        description
        duration
        recursive
        daily
        hourly
        lightCycle
        Sunset
        Sunrise
        createdAt
        updatedAt
        __typename
      }
      loop
      loopLast
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCycle = /* GraphQL */ `
  mutation CreateCycle(
    $input: CreateCycleInput!
    $condition: ModelCycleConditionInput
  ) {
    createCycle(input: $input, condition: $condition) {
      id
      name
      description
      duration
      recursive
      daily
      hourly
      lightCycle
      Sunset
      Sunrise
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCycle = /* GraphQL */ `
  mutation UpdateCycle(
    $input: UpdateCycleInput!
    $condition: ModelCycleConditionInput
  ) {
    updateCycle(input: $input, condition: $condition) {
      id
      name
      description
      duration
      recursive
      daily
      hourly
      lightCycle
      Sunset
      Sunrise
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCycle = /* GraphQL */ `
  mutation DeleteCycle(
    $input: DeleteCycleInput!
    $condition: ModelCycleConditionInput
  ) {
    deleteCycle(input: $input, condition: $condition) {
      id
      name
      description
      duration
      recursive
      daily
      hourly
      lightCycle
      Sunset
      Sunrise
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createMeasure = /* GraphQL */ `
  mutation CreateMeasure(
    $input: CreateMeasureInput!
    $condition: ModelMeasureConditionInput
  ) {
    createMeasure(input: $input, condition: $condition) {
      id
      name
      shortId
      description
      unit
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateMeasure = /* GraphQL */ `
  mutation UpdateMeasure(
    $input: UpdateMeasureInput!
    $condition: ModelMeasureConditionInput
  ) {
    updateMeasure(input: $input, condition: $condition) {
      id
      name
      shortId
      description
      unit
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteMeasure = /* GraphQL */ `
  mutation DeleteMeasure(
    $input: DeleteMeasureInput!
    $condition: ModelMeasureConditionInput
  ) {
    deleteMeasure(input: $input, condition: $condition) {
      id
      name
      shortId
      description
      unit
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createTask = /* GraphQL */ `
  mutation CreateTask(
    $input: CreateTaskInput!
    $condition: ModelTaskConditionInput
  ) {
    createTask(input: $input, condition: $condition) {
      id
      name
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateTask = /* GraphQL */ `
  mutation UpdateTask(
    $input: UpdateTaskInput!
    $condition: ModelTaskConditionInput
  ) {
    updateTask(input: $input, condition: $condition) {
      id
      name
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteTask = /* GraphQL */ `
  mutation DeleteTask(
    $input: DeleteTaskInput!
    $condition: ModelTaskConditionInput
  ) {
    deleteTask(input: $input, condition: $condition) {
      id
      name
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createMessage = /* GraphQL */ `
  mutation CreateMessage(
    $input: CreateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    createMessage(input: $input, condition: $condition) {
      id
      from
      to
      message
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateMessage = /* GraphQL */ `
  mutation UpdateMessage(
    $input: UpdateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    updateMessage(input: $input, condition: $condition) {
      id
      from
      to
      message
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteMessage = /* GraphQL */ `
  mutation DeleteMessage(
    $input: DeleteMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    deleteMessage(input: $input, condition: $condition) {
      id
      from
      to
      message
      createdAt
      updatedAt
      __typename
    }
  }
`;
