import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { API } from "aws-amplify";
import { listUnits } from "@/graphql/queries";
import { deleteUnit, createUnit, updateUnit } from "@/graphql/mutations";

export interface Units {
  data: unknown;
}

@Module
export default class UnitsModule extends VuexModule implements Units {
  data = [];

  /**
   * Get config from layout config
   * @returns {function(): *}
   */
  get units() {
    return this.data;
  }

  @Action
  async [Actions.LIST_UNITS]() {
    const response = await API.graphql({
      query: listUnits,
    });
    this.context.commit(Mutations.SET_UNITS, response);
  }
  @Mutation
  async [Mutations.SET_UNITS](payload) {
    this.data = await payload.data.listUnits.items;
  }
  @Mutation
  async [Mutations.UPDATE_UNIT](payload) {
    //const cloud = payload.host;
    //if(cloud){
    //console.log("Cloud Storage");
    const data = await API.graphql({
      query: updateUnit,
      variables: { input: payload },
    });
    console.log(data);
    //} else {
    //   console.log("Local Storage");
    // }
  }
  @Mutation
  async [Mutations.DELETE_UNIT](payload) {
    //const cloud = payload.host;
    //if(cloud){
    //console.log("Cloud Storage");
    const data = await API.graphql({
      query: deleteUnit,
      variables: { input: payload },
    });
    console.log(data);
    //} else {
    //   console.log("Local Storage");
    // }
  }
  @Mutation
  async [Mutations.CREATE_UNIT](payload) {
    const name = payload.name;
    const description = payload.description;
    const EnvironmentId = payload.EnvironmentId;
    //const zone = payload.zone;
    const cloud = true;
    if (cloud) {
      console.log("Cloud Storage");
      const unit = { name, description, EnvironmentId };
      const data = await API.graphql({
        query: createUnit,
        variables: { input: unit },
      });
      console.log(data);
    } else {
      console.log("Local Storage");
    }
  }
  @Action
  async [Actions.GET_UNITS]() {
    return await this.data;
  }
}
