import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { API } from "aws-amplify";
import { listCommands } from "@/graphql/queries";
import {
  deleteCommand,
  createCommand,
  updateCommand,
} from "@/graphql/mutations";

export interface Commands {
  data: unknown;
}

@Module
export default class CommandsModule extends VuexModule implements Commands {
  data = [];

  /**
   * Get config from layout config
   * @returns {function(): *}
   */
  get commands() {
    return this.data;
  }

  @Action
  async [Actions.LIST_COMMANDS]() {
    const response = await API.graphql({
      query: listCommands,
    });
    console.log(response);
    this.context.commit(Mutations.SET_COMMANDS, response);
  }
  @Mutation
  async [Mutations.SET_COMMANDS](payload) {
    this.data = await payload.data.listCommands.items;
  }
  @Mutation
  async [Mutations.UPDATE_COMMAND](payload) {
    //const cloud = payload.host;
    //if(cloud){
    //console.log("Cloud Storage");
    const data = await API.graphql({
      query: updateCommand,
      variables: { input: payload },
    });
    console.log(data);
    //} else {
    //   console.log("Local Storage");
    // }
  }
  @Mutation
  async [Mutations.DELETE_COMMAND](payload) {
    //const cloud = payload.host;
    //if(cloud){
    //console.log("Cloud Storage");
    const data = await API.graphql({
      query: deleteCommand,
      variables: { input: payload },
    });
    console.log(data);
    //} else {
    //   console.log("Local Storage");
    // }
  }
  @Mutation
  async [Mutations.CREATE_COMMAND](payload) {
    const unitID = payload.UnitID;
    const Command = payload.Command;
    const cloud = true;
    if (cloud) {
      //console.log("Cloud Storage");
      const out = {
        unitID,
        Command,
      };
      console.log(out);
      const data = await API.graphql({
        query: createCommand,
        variables: { input: out },
      });
      console.log(data);
    } else {
      //console.log("Local Storage");
    }
  }
  @Action
  async [Actions.GET_COMMANDS]() {
    return await this.data;
  }
}
