import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { API } from "aws-amplify";
import { listInteractors } from "@/graphql/queries";
import {
  deleteInteractor,
  createInteractor,
  updateInteractor,
} from "@/graphql/mutations";

export interface Interactors {
  data: unknown;
}

@Module
export default class InteractorsModule
  extends VuexModule
  implements Interactors
{
  data = [];

  /**
   * Get config from layout config
   * @returns {function(): *}
   */
  get interactors() {
    return this.data;
  }

  @Action
  async [Actions.LIST_INTERACTORS]() {
    const response = await API.graphql({
      query: listInteractors,
    });
    this.context.commit(Mutations.SET_INTERACTORS, response);
  }
  @Mutation
  async [Mutations.SET_INTERACTORS](payload) {
    this.data = await payload.data.listInteractors.items;
  }
  @Mutation
  async [Mutations.UPDATE_INTERACTOR](payload) {
    //const cloud = payload.host;
    //if(cloud){
    //console.log("Cloud Storage");
    const data = await API.graphql({
      query: updateInteractor,
      variables: { input: payload },
    });
    console.log(data);
    //} else {
    //   console.log("Local Storage");
    // }
  }
  @Mutation
  async [Mutations.DELETE_INTERACTOR](payload) {
    //const cloud = payload.host;
    //if(cloud){
    //console.log("Cloud Storage");
    const data = await API.graphql({
      query: deleteInteractor,
      variables: { input: payload },
    });
    console.log(data);
    //} else {
    //   console.log("Local Storage");
    // }
  }
  @Mutation
  async [Mutations.CREATE_INTERACTOR](payload) {
    const name = payload.name;
    const description = payload.description;
    const shortId = payload.shortId;
    const cloud = payload.host;
    if (cloud) {
      console.log("Cloud Storage");
      const interactor = { name, description, shortId };
      const data = await API.graphql({
        query: createInteractor,
        variables: { input: interactor },
      });
      console.log(data);
    } else {
      console.log("Local Storage");
    }
  }
  @Action
  async [Actions.GET_INTERACTORS]() {
    return await this.data;
  }
}
