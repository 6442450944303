import { createStore } from "vuex";
import { config } from "vuex-module-decorators";

import AuthModule from "@/store/modules/AuthModule";
import ZonesModule from "@/store/modules/ZonesModule";
import EnvironmentsModule from "@/store/modules/EnvironmentsModule";
import UnitsModule from "@/store/modules/UnitsModule";
import UnitStatusesModule from "@/store/modules/UnitStatusesModule";
import UnitConfigurationsModule from "@/store/modules/UnitConfigurationsModule";
import InteractorsModule from "@/store/modules/InteractorsModule";
import MeasuresModule from "@/store/modules/MeasuresModule";
import SystemsModule from "@/store/modules/SystemsModule";
import SensorsModule from "@/store/modules/SensorsModule";
import CommandsModule from "@/store/modules/CommandsModule";
import TasksModule from "@/store/modules/TasksModule";
import MessagesModule from "@/store/modules/MessagesModule";
import BodyModule from "@/store/modules/BodyModule";
import BreadcrumbsModule from "@/store/modules/BreadcrumbsModule";
import ConfigModule from "@/store/modules/ConfigModule";
import BuilderModule from "@/store/modules/BuilderModule";
import ThemeModeModule from "@/store/modules/ThemeModeModule";

config.rawError = true;

const store = createStore({
  modules: {
    AuthModule,
    ZonesModule,
    EnvironmentsModule,
    UnitsModule,
    UnitStatusesModule,
    MeasuresModule,
    UnitConfigurationsModule,
    InteractorsModule,
    SensorsModule,
    SystemsModule,
    CommandsModule,
    TasksModule,
    MessagesModule,
    BodyModule,
    BreadcrumbsModule,
    ConfigModule,
    BuilderModule,
    ThemeModeModule,
  },
});

export default store;
