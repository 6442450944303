import { createI18n } from "vue-i18n/index";

const messages = {
  en: {
    dashboard: "Dashboard",
    firmwareBuilder: "Firmware Builder",
    craft: "Crafted",
    pages: "Pages",
    profile: "Profile",
    profileOverview: "Overview",
    projects: "Projects",
    campaigns: "Campaigns",
    documents: "Documents",
    configuration: "Configuration",
    connections: "Connections",
    communication: "Communication",
    wizards: "Wizards",
    horizontal: "Horizontal",
    vertical: "Vertical",
    account: "Account",
    accountOverview: "Overview",
    settings: "Settings",
    authentication: "Authentication",
    basicFlow: "Basic Flow",
    signIn: "Sign-in",
    signUp: "Sign-up",
    passwordReset: "Password Reset",
    multiStepSignUp: "Multi-steps Sign up",
    error404: "Error 404",
    error500: "Error 500",
    apps: "Apps",
    chat: "Chat",
    privateChat: "Private Chat",
    groupChat: "Group Chat",
    drawerChat: "Drawer Chat",
    widgets: "Widgets",
    widgetsLists: "Lists",
    widgetsStatistics: "Statistics",
    widgetsCharts: "Charts",
    widgetsMixed: "Mixed",
    widgetsTables: "Tables",
    widgetsFeeds: "Feeds",
    changelog: "Changelog",
    docsAndComponents: "Documentation",
    megaMenu: "Mega Menu",
    exampleLink: "Example link",
    modals: "Modals",
    general: "General",
    inviteFriends: "Invite Friends",
    viewUsers: "View Users",
    upgradePlan: "Upgrade Plan",
    shareAndEarn: "Share & Earn",
    forms: "Forms",
    newTarget: "New Target",
    newCard: "New Card",
    newAddress: "New Address",
    createAPIKey: "Create API Key",
    twoFactorAuth: "Two Factor Auth",
    createApp: "Create App",
    createAccount: "Create Account",
    documentation: "Documentation",
    components: "Components",
    resources: "Resources",
    activity: "Activity",
    customers: "Customers",
    gettingStarted: "Getting Started",
    customersListing: "Customers Listing",
    customerDetails: "Customers Details",
    calendarApp: "Calendar",
    subscriptions: "Subscriptions",
    getStarted: "Getting Started",
    subscriptionList: "Subscription List",
    addSubscription: "Add Subscription",
    viewSubscription: "View Subscription",
    sensors: "Sensors",
    measures: "Measures",
    interactors: "Interactors",
    systems: "Systems",
    firmware: "Firmware",
    builder: "Builder",
    code: "Code",
    locations: "Locations",
    environment: "Environment",
    unit: "Unit",
  },
  es: {
    dashboard: "Tablero",
    firmwareBuiler: "Constructor de maquetación",
    craft: "Elaborado",
    pages: "Paginas",
    profile: "Perfil",
    profileOverview: "Descripción general",
    projects: "Proyectos",
    campaigns: "Campañas",
    documents: "Documentos",
    configuration: "Configuracion",
    connections: "Conexiones",
    wizards: "Magos",
    horizontal: "Horizontal",
    vertical: "Vertical",
    account: "Cuenta",
    accountOverview: "Descripción general",
    settings: "Ajustes",
    authentication: "Autenticación",
    basicFlow: "Flujo básico",
    signIn: "Registrarse",
    signUp: "Inscribirse",
    passwordReset: "Restablecimiento de contraseña",
    multiStepSignUp: "Regístrese Multi-Pasos",
    error404: "Error 404",
    error500: "Error 500",
    apps: "Aplicaciones",
    chat: "Chat",
    privateChat: "Chat privado",
    groupChat: "Grupo de chat",
    drawerChat: "Chat del cajón",
    widgets: "Widgets",
    widgetsLists: "Liza",
    widgetsStatistics: "Estadísticas",
    widgetsCharts: "Gráficos",
    widgetsMixed: "Mezclada",
    widgetsTables: "Mesas",
    widgetsFeeds: "Alimenta",
    changelog: "Registro de cambios",
    docsAndComponents: "Documentos & Componentes",
    megaMenu: "Mega menú",
    exampleLink: "Enlace de ejemplo",
    modals: "Modales",
    general: "General",
    inviteFriends: "Invitar A Amigos",
    viewUsers: "Ver Usuarios",
    upgradePlan: "Plan De Actualización",
    shareAndEarn: "Compartir Y Ganar",
    forms: "Formas",
    newTarget: "Nuevo Objetivo",
    newCard: "Nueva Tarjeta",
    newAddress: "Nueva Direccion",
    createAPIKey: "Crea Clave De Api",
    twoFactorAuth: "Dos Factores",
    createApp: "Crear Aplicacion",
    createAccount: "Crear Una Cuenta",
    activity: "Actividad",
    documentation: "Documentación",
    components: "Componentes",
    resources: "Recursos",
    customers: "Clientes",
    gettingStarted: "Empezando",
    customersListing: "Listado De Clientes",
    customerDetails: "Detalles De Los Clientes",
    calendarApp: "Calendario",
    subscriptions: "Suscripciones",
    getStarted: "Empezando",
    subscriptionList: "Lista De Suscripción",
    addSubscription: "Añadir Suscripción",
    viewSubscription: "Suscripción",
    sensors: "Sensores",
    measures: "Medidas",
    interactors: "Interactores",
    systems: "Sistemas",
    firmware: "Firmware", // Added "Firmware" translation in Spanish
    builder: "Constructor", // Added "Builder" translation in Spanish
    code: "Código", // Added "Code" translation in Spanish
    locations: "Ubicaciones", // Added "Location" translation in Spanish
    environment: "Entorno", // Added "Environment" translation in Spanish
    unit: "Unidad", // Added "Unit" translation in Spanish
  },
  de: {
    dashboard: "Instrumententafel",
    firmwareBuidler: "Firmware-Builder",
    craft: "Hergestellt",
    pages: "Seiten",
    profile: "Profil",
    profileOverview: "Überblick",
    projects: "Projekte",
    campaigns: "Kampagnen",
    documents: "Unterlagen",
    configuration: "Einstellung",
    connections: "Anschlüsse",
    communication: "Kommunikation",
    wizards: "Zauberer",
    horizontal: "Horizontal",
    vertical: "Vertikal",
    account: "Konto",
    accountOverview: "Überblick",
    settings: "Die Einstellungen",
    authentication: "Authentifizierung",
    basicFlow: "Grundfluss",
    signIn: "Einloggen",
    signUp: "Anmelden",
    passwordReset: "Passwort zurücksetzen",
    multiStepSignUp: "Multi-Steps-Anmeldung",
    error404: "Fehler 404",
    error500: "Fehler 500",
    apps: "Apps",
    chat: "Plaudern",
    privateChat: "Private Chat",
    groupChat: "Privater Chat",
    drawerChat: "Gruppenchat Schubladen-Chat",
    widgets: "Widgets",
    widgetsLists: "Listen",
    widgetsStatistics: "Statistiken",
    widgetsCharts: "Diagramme",
    widgetsMixed: "Gemischt",
    widgetsTables: "Tabellen",
    widgetsFeeds: "Einspeisungen",
    changelog: "Änderungsprotokoll",
    docsAndComponents: "Dokumente & Komponenten",
    megaMenu: "Mega-Menü",
    exampleLink: "Beispiellink",
    modals: "Modale",
    general: "Allgemeines",
    inviteFriends: "Freunde Einladen",
    viewUsers: "Benutzer Anzeigen.",
    upgradePlan: "Upgrade-Plan",
    shareAndEarn: "Teilen & Verdienen",
    forms: "Formen",
    newTarget: "Neues Ziel",
    newCard: "Neue Karte",
    newAddress: "Neue Adresse",
    createAPIKey: "Api-Key Erstellen",
    twoFactorAuth: "Zwei Faktor Auth.",
    createApp: "App Erstellen",
    createAccount: "Benutzerkonto Erstellen",
    activity: "Aktivität",
    documentation: "Dokumentation",
    components: "Bauteile",
    resources: "Ressourcen",
    customers: "Kunden",
    gettingStarted: "Einstieg",
    customersListing: "Kundenauflistung",
    customerDetails: "Kundenangaben",
    calendarApp: "Kalender",
    subscriptions: "Abonnements",
    getStarted: "Einstieg",
    subscriptionList: "Abonnementliste",
    addSubscription: "Subskription Hinzufügen.",
    viewSubscription: "Abonnement Anzeigen.",
    sensors: "Sensoren",
    measures: "Messen",
    interactors: "Interaktors",
    systems: "Systeme",
    firmware: "Firmware", // Added "Firmware" translation in German
    builder: "Ersteller", // Added "Builder" translation in German
    code: "Code", // Added "Code" translation in German
    locations: "Orte", // Added "Location" translation in German
    environment: "Umwelt", // Added "Environment" translation in German
    unit: "Einheit", // Added "Unit" translation in German
  },
};

const i18n = createI18n({
  legacy: false,
  locale: "en",
  globalInjection: true,
  messages,
});

export default i18n;
