/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUserInfo = /* GraphQL */ `
  query GetUserInfo($id: ID!) {
    getUserInfo(id: $id) {
      id
      username
      firstName
      lastName
      email
      role
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUserInfos = /* GraphQL */ `
  query ListUserInfos(
    $filter: ModelUserInfoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserInfos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        username
        firstName
        lastName
        email
        role
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getZone = /* GraphQL */ `
  query GetZone($id: ID!) {
    getZone(id: $id) {
      id
      name
      description
      environments {
        items {
          id
          ZoneId
          zone {
            id
            name
            description
            owner
            createdAt
            updatedAt
            __typename
          }
          name
          description
          unit {
            nextToken
            __typename
          }
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listZones = /* GraphQL */ `
  query ListZones(
    $filter: ModelZoneFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listZones(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        environments {
          items {
            id
            ZoneId
            name
            description
            owner
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        owner
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEnvironment = /* GraphQL */ `
  query GetEnvironment($id: ID!) {
    getEnvironment(id: $id) {
      id
      ZoneId
      zone {
        id
        name
        description
        environments {
          items {
            id
            ZoneId
            name
            description
            owner
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        owner
        createdAt
        updatedAt
        __typename
      }
      name
      description
      unit {
        items {
          id
          EnvironmentId
          environment {
            id
            ZoneId
            name
            description
            owner
            createdAt
            updatedAt
            __typename
          }
          name
          shortId
          description
          configuration {
            id
            UnitId
            firmware
            usb_port
            usb_baud_rate
            server_listen
            server_send
            server_baud_rate
            async
            wifi
            network
            password
            board
            shield
            bluetooth
            radio
            number_of_groups
            createdAt
            updatedAt
            __typename
          }
          pinout {
            id
            UnitId
            s
            i
            m
            createdAt
            updatedAt
            __typename
          }
          status {
            id
            UnitId
            obs
            createdAt
            updatedAt
            __typename
          }
          historic {
            nextToken
            __typename
          }
          commands {
            nextToken
            __typename
          }
          cycle {
            id
            name
            description
            duration
            recursive
            daily
            hourly
            lightCycle
            Sunset
            Sunrise
            createdAt
            updatedAt
            __typename
          }
          profile {
            id
            name
            description
            loop
            loopLast
            createdAt
            updatedAt
            __typename
          }
          cycles
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listEnvironments = /* GraphQL */ `
  query ListEnvironments(
    $filter: ModelEnvironmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEnvironments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        ZoneId
        zone {
          id
          name
          description
          environments {
            nextToken
            __typename
          }
          owner
          createdAt
          updatedAt
          __typename
        }
        name
        description
        unit {
          items {
            id
            EnvironmentId
            name
            shortId
            description
            cycles
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        owner
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUnitStatus = /* GraphQL */ `
  query GetUnitStatus($UnitId: ID!) {
    getUnitStatus(UnitId: $UnitId) {
      id
      UnitId
      obs
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUnitStatuses = /* GraphQL */ `
  query ListUnitStatuses(
    $UnitId: ID
    $filter: ModelUnitStatusFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUnitStatuses(
      UnitId: $UnitId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        UnitId
        obs
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUnit = /* GraphQL */ `
  query GetUnit($id: ID!) {
    getUnit(id: $id) {
      id
      EnvironmentId
      environment {
        id
        ZoneId
        zone {
          id
          name
          description
          environments {
            nextToken
            __typename
          }
          owner
          createdAt
          updatedAt
          __typename
        }
        name
        description
        unit {
          items {
            id
            EnvironmentId
            name
            shortId
            description
            cycles
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        owner
        createdAt
        updatedAt
        __typename
      }
      name
      shortId
      description
      configuration {
        id
        UnitId
        firmware
        usb_port
        usb_baud_rate
        server_listen
        server_send
        server_baud_rate
        async
        wifi
        network
        password
        board
        shield
        bluetooth
        radio
        number_of_groups
        groups {
          sensors {
            i
            __typename
          }
          interactors {
            i
            __typename
          }
          systems {
            i
            __typename
          }
          modules {
            i
            __typename
          }
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      pinout {
        id
        UnitId
        s
        i
        m
        createdAt
        updatedAt
        __typename
      }
      status {
        id
        UnitId
        obs
        createdAt
        updatedAt
        __typename
      }
      historic {
        items {
          id
          unitID
          obs
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      commands {
        items {
          id
          unitID
          Command
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cycle {
        id
        name
        description
        duration
        recursive
        daily
        hourly
        lightCycle
        Sunset
        Sunrise
        createdAt
        updatedAt
        __typename
      }
      profile {
        id
        name
        description
        cycles {
          id
          name
          description
          duration
          recursive
          daily
          hourly
          lightCycle
          Sunset
          Sunrise
          createdAt
          updatedAt
          __typename
        }
        loop
        loopLast
        createdAt
        updatedAt
        __typename
      }
      cycles
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUnits = /* GraphQL */ `
  query ListUnits(
    $filter: ModelUnitFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUnits(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        EnvironmentId
        environment {
          id
          ZoneId
          zone {
            id
            name
            description
            owner
            createdAt
            updatedAt
            __typename
          }
          name
          description
          unit {
            nextToken
            __typename
          }
          owner
          createdAt
          updatedAt
          __typename
        }
        name
        shortId
        description
        configuration {
          id
          UnitId
          firmware
          usb_port
          usb_baud_rate
          server_listen
          server_send
          server_baud_rate
          async
          wifi
          network
          password
          board
          shield
          bluetooth
          radio
          number_of_groups
          groups {
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        pinout {
          id
          UnitId
          s
          i
          m
          createdAt
          updatedAt
          __typename
        }
        status {
          id
          UnitId
          obs
          createdAt
          updatedAt
          __typename
        }
        historic {
          items {
            id
            unitID
            obs
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        commands {
          items {
            id
            unitID
            Command
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        cycle {
          id
          name
          description
          duration
          recursive
          daily
          hourly
          lightCycle
          Sunset
          Sunrise
          createdAt
          updatedAt
          __typename
        }
        profile {
          id
          name
          description
          cycles {
            id
            name
            description
            duration
            recursive
            daily
            hourly
            lightCycle
            Sunset
            Sunrise
            createdAt
            updatedAt
            __typename
          }
          loop
          loopLast
          createdAt
          updatedAt
          __typename
        }
        cycles
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCommand = /* GraphQL */ `
  query GetCommand($id: ID!) {
    getCommand(id: $id) {
      id
      unitID
      Command
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCommands = /* GraphQL */ `
  query ListCommands(
    $filter: ModelCommandFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCommands(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        unitID
        Command
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getHistoric = /* GraphQL */ `
  query GetHistoric($id: ID!) {
    getHistoric(id: $id) {
      id
      unitID
      obs
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listHistorics = /* GraphQL */ `
  query ListHistorics(
    $filter: ModelHistoricFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHistorics(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        unitID
        obs
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUnitConfiguration = /* GraphQL */ `
  query GetUnitConfiguration($UnitId: ID!) {
    getUnitConfiguration(UnitId: $UnitId) {
      id
      UnitId
      firmware
      usb_port
      usb_baud_rate
      server_listen
      server_send
      server_baud_rate
      async
      wifi
      network
      password
      board
      shield
      bluetooth
      radio
      number_of_groups
      groups {
        sensors {
          i
          p {
            v
            __typename
          }
          __typename
        }
        interactors {
          i
          p {
            v
            __typename
          }
          __typename
        }
        systems {
          i
          p {
            v
            __typename
          }
          __typename
        }
        modules {
          i
          p {
            v
            __typename
          }
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUnitConfigurations = /* GraphQL */ `
  query ListUnitConfigurations(
    $UnitId: ID
    $filter: ModelUnitConfigurationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUnitConfigurations(
      UnitId: $UnitId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        UnitId
        firmware
        usb_port
        usb_baud_rate
        server_listen
        server_send
        server_baud_rate
        async
        wifi
        network
        password
        board
        shield
        bluetooth
        radio
        number_of_groups
        groups {
          sensors {
            i
            __typename
          }
          interactors {
            i
            __typename
          }
          systems {
            i
            __typename
          }
          modules {
            i
            __typename
          }
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUnitPinout = /* GraphQL */ `
  query GetUnitPinout($UnitId: ID!) {
    getUnitPinout(UnitId: $UnitId) {
      id
      UnitId
      s
      i
      m
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUnitPinouts = /* GraphQL */ `
  query ListUnitPinouts(
    $UnitId: ID
    $filter: ModelUnitPinoutFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUnitPinouts(
      UnitId: $UnitId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        UnitId
        s
        i
        m
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getInteractor = /* GraphQL */ `
  query GetInteractor($id: ID!) {
    getInteractor(id: $id) {
      id
      name
      shortId
      description
      pins
      commands
      type
      category
      allowMultiple
      variables
      bootScript
      printScript
      stateScript
      commandsScript
      functionsScript
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listInteractors = /* GraphQL */ `
  query ListInteractors(
    $filter: ModelInteractorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInteractors(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        shortId
        description
        pins
        commands
        type
        category
        allowMultiple
        variables
        bootScript
        printScript
        stateScript
        commandsScript
        functionsScript
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSensor = /* GraphQL */ `
  query GetSensor($id: ID!) {
    getSensor(id: $id) {
      id
      name
      shortId
      description
      powerSupply
      sensingPeriod
      allowMultiple
      manufactor
      variables
      bootScript
      printScript
      stateScript
      commandsScript
      functionsScript
      category
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listSensors = /* GraphQL */ `
  query ListSensors(
    $filter: ModelSensorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSensors(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        shortId
        description
        powerSupply
        sensingPeriod
        allowMultiple
        manufactor
        variables
        bootScript
        printScript
        stateScript
        commandsScript
        functionsScript
        category
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSystem = /* GraphQL */ `
  query GetSystem($id: ID!) {
    getSystem(id: $id) {
      id
      name
      shortId
      description
      variables
      bootScript
      printScript
      stateScript
      commandsScript
      functionsScript
      communicationScript
      category
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listSystems = /* GraphQL */ `
  query ListSystems(
    $filter: ModelSystemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSystems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        shortId
        description
        variables
        bootScript
        printScript
        stateScript
        commandsScript
        functionsScript
        communicationScript
        category
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSeedBatch = /* GraphQL */ `
  query GetSeedBatch($id: ID!) {
    getSeedBatch(id: $id) {
      id
      batch
      date
      grower
      description
      numberOfSeeds
      germinationRate
      seedLife
      taxonomy
      variety
      commonName
      pollination
      quality
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listSeedBatches = /* GraphQL */ `
  query ListSeedBatches(
    $filter: ModelSeedBatchFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSeedBatches(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        batch
        date
        grower
        description
        numberOfSeeds
        germinationRate
        seedLife
        taxonomy
        variety
        commonName
        pollination
        quality
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getProfile = /* GraphQL */ `
  query GetProfile($id: ID!) {
    getProfile(id: $id) {
      id
      name
      description
      cycles {
        id
        name
        description
        duration
        recursive
        daily
        hourly
        lightCycle
        Sunset
        Sunrise
        createdAt
        updatedAt
        __typename
      }
      loop
      loopLast
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listProfiles = /* GraphQL */ `
  query ListProfiles(
    $filter: ModelProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProfiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        cycles {
          id
          name
          description
          duration
          recursive
          daily
          hourly
          lightCycle
          Sunset
          Sunrise
          createdAt
          updatedAt
          __typename
        }
        loop
        loopLast
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCycle = /* GraphQL */ `
  query GetCycle($id: ID!) {
    getCycle(id: $id) {
      id
      name
      description
      duration
      recursive
      daily
      hourly
      lightCycle
      Sunset
      Sunrise
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCycles = /* GraphQL */ `
  query ListCycles(
    $filter: ModelCycleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCycles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        duration
        recursive
        daily
        hourly
        lightCycle
        Sunset
        Sunrise
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMeasure = /* GraphQL */ `
  query GetMeasure($id: ID!) {
    getMeasure(id: $id) {
      id
      name
      shortId
      description
      unit
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listMeasures = /* GraphQL */ `
  query ListMeasures(
    $filter: ModelMeasureFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMeasures(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        shortId
        description
        unit
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTask = /* GraphQL */ `
  query GetTask($id: ID!) {
    getTask(id: $id) {
      id
      name
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listTasks = /* GraphQL */ `
  query ListTasks(
    $filter: ModelTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTasks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMessage = /* GraphQL */ `
  query GetMessage($id: ID!) {
    getMessage(id: $id) {
      id
      from
      to
      message
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listMessages = /* GraphQL */ `
  query ListMessages(
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        from
        to
        message
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const environmentsByZoneId = /* GraphQL */ `
  query EnvironmentsByZoneId(
    $ZoneId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelEnvironmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    environmentsByZoneId(
      ZoneId: $ZoneId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ZoneId
        zone {
          id
          name
          description
          environments {
            nextToken
            __typename
          }
          owner
          createdAt
          updatedAt
          __typename
        }
        name
        description
        unit {
          items {
            id
            EnvironmentId
            name
            shortId
            description
            cycles
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        owner
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const unitsByEnvironmentId = /* GraphQL */ `
  query UnitsByEnvironmentId(
    $EnvironmentId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUnitFilterInput
    $limit: Int
    $nextToken: String
  ) {
    unitsByEnvironmentId(
      EnvironmentId: $EnvironmentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        EnvironmentId
        environment {
          id
          ZoneId
          zone {
            id
            name
            description
            owner
            createdAt
            updatedAt
            __typename
          }
          name
          description
          unit {
            nextToken
            __typename
          }
          owner
          createdAt
          updatedAt
          __typename
        }
        name
        shortId
        description
        configuration {
          id
          UnitId
          firmware
          usb_port
          usb_baud_rate
          server_listen
          server_send
          server_baud_rate
          async
          wifi
          network
          password
          board
          shield
          bluetooth
          radio
          number_of_groups
          groups {
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        pinout {
          id
          UnitId
          s
          i
          m
          createdAt
          updatedAt
          __typename
        }
        status {
          id
          UnitId
          obs
          createdAt
          updatedAt
          __typename
        }
        historic {
          items {
            id
            unitID
            obs
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        commands {
          items {
            id
            unitID
            Command
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        cycle {
          id
          name
          description
          duration
          recursive
          daily
          hourly
          lightCycle
          Sunset
          Sunrise
          createdAt
          updatedAt
          __typename
        }
        profile {
          id
          name
          description
          cycles {
            id
            name
            description
            duration
            recursive
            daily
            hourly
            lightCycle
            Sunset
            Sunrise
            createdAt
            updatedAt
            __typename
          }
          loop
          loopLast
          createdAt
          updatedAt
          __typename
        }
        cycles
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const commandsByUnitID = /* GraphQL */ `
  query CommandsByUnitID(
    $unitID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCommandFilterInput
    $limit: Int
    $nextToken: String
  ) {
    commandsByUnitID(
      unitID: $unitID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        unitID
        Command
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const historicsByUnitID = /* GraphQL */ `
  query HistoricsByUnitID(
    $unitID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelHistoricFilterInput
    $limit: Int
    $nextToken: String
  ) {
    historicsByUnitID(
      unitID: $unitID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        unitID
        obs
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
