import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { API } from "aws-amplify";
import { listSystems } from "@/graphql/queries";
import { deleteSystem, createSystem, updateSystem } from "@/graphql/mutations";

export interface Systems {
  data: unknown;
}

@Module
export default class SystemsModule extends VuexModule implements Systems {
  data = [];

  /**
   * Get config from layout config
   * @returns {function(): *}
   */
  get systems() {
    return this.data;
  }

  @Action
  async [Actions.LIST_SYSTEMS]() {
    const response = await API.graphql({
      query: listSystems,
    });
    this.context.commit(Mutations.SET_SYSTEMS, response);
  }
  @Mutation
  async [Mutations.SET_SYSTEMS](payload) {
    console.log(payload);
    this.data = await payload.data.listSystems.items;
  }
  @Mutation
  async [Mutations.UPDATE_SYSTEM](payload) {
    //const cloud = payload.host;
    //if(cloud){
    //console.log("Cloud Storage");
    const data = await API.graphql({
      query: updateSystem,
      variables: { input: payload },
    });
    console.log(data);
    //} else {
    //   console.log("Local Storage");
    // }
  }
  @Mutation
  async [Mutations.DELETE_SYSTEM](payload) {
    //const cloud = payload.host;
    //if(cloud){
    //console.log("Cloud Storage");
    const data = await API.graphql({
      query: deleteSystem,
      variables: { input: payload },
    });
    console.log(data);
    //} else {
    //   console.log("Local Storage");
    // }
  }
  @Mutation
  async [Mutations.CREATE_SYSTEM](payload) {
    const name = payload.name;
    const description = payload.description;
    const shortId = payload.shortId;
    const cloud = payload.host;
    if (cloud) {
      console.log("Cloud Storage");
      const system = { name, description, shortId };
      const data = await API.graphql({
        query: createSystem,
        variables: { input: system },
      });
      console.log(data);
    } else {
      console.log("Local Storage");
    }
  }
  @Action
  async [Actions.GET_SYSTEMS]() {
    return await this.data;
  }
}
