import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { API } from "aws-amplify";
import { listEnvironments } from "@/graphql/queries";
import {
  deleteEnvironment,
  createEnvironment,
  updateEnvironment,
} from "@/graphql/mutations";

export interface Environments {
  data: unknown;
}

@Module
export default class EnvironmentsModule
  extends VuexModule
  implements Environments
{
  data = [];

  /**
   * Get config from layout config
   * @returns {function(): *}
   */
  get environments() {
    return this.data;
  }

  @Action
  async [Actions.LIST_ENVIRONMENTS]() {
    const response = await API.graphql({
      query: listEnvironments,
    });
    this.context.commit(Mutations.SET_ENVIRONMENTS, response);
  }
  @Mutation
  async [Mutations.SET_ENVIRONMENTS](payload) {
    this.data = await payload.data.listEnvironments.items;
  }
  @Mutation
  async [Mutations.UPDATE_ENVIRONMENT](payload) {
    //const cloud = payload.host;
    //if(cloud){
    //console.log("Cloud Storage");
    const data = await API.graphql({
      query: updateEnvironment,
      variables: { input: payload },
    });
    console.log(data);
    //} else {
    //   console.log("Local Storage");
    // }
  }
  @Mutation
  async [Mutations.DELETE_ENVIRONMENT](payload) {
    //const cloud = payload.host;
    //if(cloud){
    //console.log("Cloud Storage");
    const data = await API.graphql({
      query: deleteEnvironment,
      variables: { input: payload },
    });
    console.log(data);
    //} else {
    //   console.log("Local Storage");
    // }
  }
  @Mutation
  async [Mutations.CREATE_ENVIRONMENT](payload) {
    const name = payload.name;
    const description = payload.description;
    const ZoneId = payload.ZoneId;
    //const zone = payload.zone;
    const cloud = true;
    if (cloud) {
      console.log("Cloud Storage");
      const environment = { name, description, ZoneId };
      const data = await API.graphql({
        query: createEnvironment,
        variables: { input: environment },
      });
      console.log(data);
    } else {
      console.log("Local Storage");
    }
  }
  @Action
  async [Actions.GET_ENVIRONMENTS]() {
    return await this.data;
  }
}
