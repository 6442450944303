import objectPath from "object-path";
import builderConfig from "@/core/config/DefaultBuilderConfig";
import { Mutations } from "@/store/enums/StoreEnums";
import { Mutation, Module, VuexModule } from "vuex-module-decorators";
import BuilderConfigTypes from "@/core/config/BuilderConfigTypes";
//import { API } from "aws-amplify";

import AWS from "aws-sdk";
//import Lambda from "@aws-sdk/client-lambda";

interface StoreInfo {
  config: BuilderConfigTypes;
  initial: BuilderConfigTypes;
}

@Module
export default class ConfigModule extends VuexModule implements StoreInfo {
  config: BuilderConfigTypes = builderConfig;
  initial: BuilderConfigTypes = builderConfig;

  /**
   * Get config from layout config
   * @returns {function(path, defaultValue): *}
   */
  get builderConfig() {
    return (path, defaultValue) => {
      return objectPath.get(this.config, path, defaultValue);
    };
  }

  @Mutation
  [Mutations.SET_BUILDER_CONFIG](payload): void {
    this.config = payload;
  }

  @Mutation
  [Mutations.OVERRIDE_BUILDER_CONFIG](): void {
    this.config = this.initial = Object.assign(
      {},
      this.initial,
      JSON.parse(window.localStorage.getItem("builderConfig") || "{}")
    );
  }

  @Mutation
  async [Mutations.BUILD_FIRMWARE](payload) {
    const config = new AWS.Config({
      region: "us-east-1",
      accessKeyId: "AKIAUFAXR7WQF2PY2GXN",
      secretAccessKey: "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",
    });

    const lambda_fun = new AWS.Lambda(config);

    const pullParams = {
      FunctionName: "Builder-A-dev",
      InvocationType: "RequestResponse",
      LogType: "None",
      Payload: JSON.stringify(payload),
    };
    //var pullResults;

    lambda_fun.invoke(pullParams, function (error, data) {
      if (error) console.log(error);
      else {
        console.log(data);
        //console.log('returned result: ', JSON.stringify(pullResults, null, 2))
      }
    });
  }
}
