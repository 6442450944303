import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { API } from "aws-amplify";
import { listMessages } from "@/graphql/queries";
import {
  deleteMessage,
  createMessage,
  updateMessage,
} from "@/graphql/mutations";

export interface Messages {
  data: unknown;
}

@Module
export default class MessagesModule extends VuexModule implements Messages {
  data = [];

  /**
   * Get config from layout config
   * @returns {function(): *}
   */
  get messages() {
    return this.data;
  }

  @Action
  async [Actions.LIST_MESSAGES]() {
    const response = await API.graphql({
      query: listMessages,
    });
    this.context.commit(Mutations.SET_MESSAGES, response);
  }
  @Mutation
  async [Mutations.SET_MESSAGES](payload) {
    this.data = await payload.data.listMessages.items;
  }
  @Mutation
  async [Mutations.UPDATE_MESSAGE](payload) {
    //const cloud = payload.host;
    //if(cloud){
    //console.log("Cloud Storage");
    const data = await API.graphql({
      query: updateMessage,
      variables: { input: payload },
    });
    console.log(data);
    //} else {
    //   console.log("Local Storage");
    // }
  }
  @Mutation
  async [Mutations.DELETE_MESSAGE](payload) {
    //const cloud = payload.host;
    //if(cloud){
    //console.log("Cloud Storage");
    const data = await API.graphql({
      query: deleteMessage,
      variables: { input: payload },
    });
    console.log(data);
    //} else {
    //   console.log("Local Storage");
    // }
  }
  @Mutation
  async [Mutations.CREATE_MESSAGE](payload) {
    const from = payload.from;
    const to = payload.to;
    const message = payload.message;
    const cloud = true;
    if (cloud) {
      console.log("Cloud Storage");
      const input = { from, to, message };
      const data = await API.graphql({
        query: createMessage,
        variables: { input: input },
      });
      console.log(data);
    } else {
      console.log("Local Storage");
    }
  }
  @Action
  async [Actions.GET_MESSAGES]() {
    return await this.data;
  }
}
