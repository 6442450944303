import BuilderConfigTypes from "@/core/config/BuilderConfigTypes";

const config: BuilderConfigTypes = {
  randomString: "",
  package: "",
  id: "",
  UnitId: "",
  firmware: "",
  author: "",
  network: "",
  password: "",
  board: "",
  shield: "",
  usb_port: "",
  usb_baud_rate: "",
  server_send: "",
  server_listen: "",
  server_baud_rate: "",
  async: "0",
  wifi: "0",
  bluetooth: "0",
  radio: "0",
  number_of_groups: 1,
  groups: [
    {
      sensors: [
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
      ],
      interactors: [
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
      ],
      systems: [
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
      ],
      modules: [
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
      ],
    },
    {
      sensors: [
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
      ],
      interactors: [
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
      ],
      systems: [
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
      ],
      modules: [
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
      ],
    },
    {
      sensors: [
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
      ],
      interactors: [
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
      ],
      systems: [
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
      ],
      modules: [
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
      ],
    },
    {
      sensors: [
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
      ],
      interactors: [
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
      ],
      systems: [
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
      ],
      modules: [
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
      ],
    },
    {
      sensors: [
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
      ],
      interactors: [
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
      ],
      systems: [
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
      ],
      modules: [
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
      ],
    },
    {
      sensors: [
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
      ],
      interactors: [
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
      ],
      systems: [
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
      ],
      modules: [
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
      ],
    },
    {
      sensors: [
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
      ],
      interactors: [
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
      ],
      systems: [
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
      ],
      modules: [
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
      ],
    },
    {
      sensors: [
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
      ],
      interactors: [
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
      ],
      systems: [
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
      ],
      modules: [
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
        { i: "", p: [{ v: "" }, { v: "" }, { v: "" }, { v: "" }] },
      ],
    },
  ],
};

export default config;
