import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { API } from "aws-amplify";
import { listZones } from "@/graphql/queries";
import { deleteZone, createZone, updateZone } from "@/graphql/mutations";

export interface Zones {
  data: unknown;
}

@Module
export default class ZonesModule extends VuexModule implements Zones {
  data = [];

  /**
   * Get config from layout config
   * @returns {function(): *}
   */
  get zones() {
    return this.data;
  }

  @Action
  async [Actions.LIST_ZONES]() {
    const response = await API.graphql({
      query: listZones,
    });
    this.context.commit(Mutations.SET_ZONES, response);
  }
  @Mutation
  async [Mutations.SET_ZONES](payload) {
    this.data = await payload.data.listZones.items;
  }
  @Mutation
  async [Mutations.UPDATE_ZONE](payload) {
    //const cloud = payload.host;
    //if(cloud){
    //console.log("Cloud Storage");
    const data = await API.graphql({
      query: updateZone,
      variables: { input: payload },
    });
    console.log(data);
    //} else {
    //   console.log("Local Storage");
    // }
  }
  @Mutation
  async [Mutations.DELETE_ZONE](payload) {
    //const cloud = payload.host;
    //if(cloud){
    //console.log("Cloud Storage");
    const data = await API.graphql({
      query: deleteZone,
      variables: { input: payload },
    });
    console.log(data);
    //} else {
    //   console.log("Local Storage");
    // }
  }
  @Mutation
  async [Mutations.CREATE_ZONE](payload) {
    const name = payload.name;
    const description = payload.description;
    const cloud = true;
    if (cloud) {
      console.log("Cloud Storage");
      const zone = { name, description };
      const data = await API.graphql({
        query: createZone,
        variables: { input: zone },
      });
      console.log(data);
    } else {
      console.log("Local Storage");
    }
  }
  @Action
  async [Actions.GET_ZONES]() {
    return await this.data;
  }
}
