import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { API } from "aws-amplify";
import { listUnitStatuses } from "@/graphql/queries";
import {
  deleteUnitStatus,
  createUnitStatus,
  updateUnitStatus,
} from "@/graphql/mutations";

export interface UnitStatuses {
  data: unknown;
}

@Module
export default class UnitStatusesModule
  extends VuexModule
  implements UnitStatuses
{
  data = [];

  /**
   * Get config from layout config
   * @returns {function(): *}
   */
  get unitStatuses() {
    return this.data;
  }

  @Action
  async [Actions.LIST_UNIT_STATUSES]() {
    const response = await API.graphql({
      query: listUnitStatuses,
    });
    //console.log(response);
    this.context.commit(Mutations.SET_UNIT_STATUSES, response);
  }
  @Mutation
  async [Mutations.SET_UNIT_STATUSES](payload) {
    this.data = await payload.data.listUnitStatuses.items;
  }
  @Mutation
  async [Mutations.UPDATE_UNIT_STATUS](payload) {
    //const cloud = payload.host;
    //if(cloud){
    //console.log("Cloud Storage");
    const data = await API.graphql({
      query: updateUnitStatus,
      variables: { input: payload },
    });
    console.log(data);
    //} else {
    //   console.log("Local Storage");
    // }
  }
  @Mutation
  async [Mutations.DELETE_UNIT_STATUS](payload) {
    //const cloud = payload.host;
    //if(cloud){
    //console.log("Cloud Storage");
    const data = await API.graphql({
      query: deleteUnitStatus,
      variables: { input: payload },
    });
    console.log(data);
    //} else {
    //   console.log("Local Storage");
    // }
  }
  @Mutation
  async [Mutations.CREATE_UNIT_STATUS](payload) {
    const UnitId = payload.UnitId;
    //const zone = payload.zone;
    const cloud = true;
    if (cloud) {
      //console.log("Cloud Storage");
      //console.log(payload);
      const unitStatus = {
        UnitId,
      };
      console.log(unitStatus);
      const data = await API.graphql({
        query: createUnitStatus,
        variables: { input: unitStatus },
      });
      console.log(data);
    } else {
      //console.log("Local Storage");
    }
  }
  @Action
  async [Actions.GET_UNIT_STATUSES]() {
    return await this.data;
  }
}
