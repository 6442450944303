import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { Auth } from "aws-amplify";
import { API } from "aws-amplify";
import { createUserInfo } from "@/graphql/mutations";
import { listUserInfos } from "@/graphql/queries";

export interface User {
  name: string;
  surname: string;
  email: string;
  password: string;
  api_token: string;
}

export interface UserAuthInfo {
  errors: unknown;
  user: User;
  isAuthenticated: boolean;
  localHost: boolean;
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
  errors = {};
  user = {} as User;
  isAuthenticated = !!JwtService.getToken();
  localHost = false;

  /**
   * Get current user object
   * @returns User
   */
  get currentUser(): User {
    return this.user;
  }

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {
    return this.isAuthenticated;
  }

  /**
   * Verify user host
   * @returns boolean
   */
  get isLocalHost(): boolean {
    return this.localHost;
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get getErrors() {
    return this.errors;
  }

  usersList = [];

  /**
   * Get users list
   * @returns {function(): *}
   */
  get userInfos() {
    return this.usersList;
  }

  @Action
  async [Actions.LIST_USER_INFOS]() {
    const response = await API.graphql({
      query: listUserInfos,
    });
    //console.log(response);
    this.context.commit(Mutations.SET_USER_INFOS, response);
  }
  @Mutation
  async [Mutations.SET_USER_INFOS](payload) {
    //console.log(payload);
    this.usersList = await payload.data.listUserInfos.items;
    //console.log(this.usersList);
  }

  @Action
  async [Actions.GET_USER_INFOS]() {
    return await this.usersList;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = { ...error };
  }

  @Mutation
  [Mutations.SET_AUTH](user) {
    if (user.localHost == true) {
      this.localHost = true;
      console.log("Local Host.");
    }
    this.isAuthenticated = true;
    this.user = user;
    this.errors = {};
    JwtService.saveToken(user.signInUserSession.accessToken.jwtToken);
  }

  @Mutation
  [Mutations.SET_USER](user) {
    this.user = user;
  }

  @Mutation
  [Mutations.SET_PASSWORD](password) {
    this.user.password = password;
  }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = false;
    this.user = {} as User;
    this.errors = [];
    JwtService.destroyToken();
  }

  @Action
  [Actions.LOGIN](credentials) {
    const params = {
      params: {
        ...credentials,
      },
    };
    return Auth.signIn(params.params.email, params.params.password)
      .then((data) => {
        //console.log(data);
        this.context.commit(Mutations.SET_AUTH, data);
      })
      .catch((error) => {
        //let data = {};
        alert(error);
        //data = { localHost: true };
        //this.context.commit(Mutations.SET_AUTH, data);
        //this.context.commit(Mutations.SET_ERROR, error);
      });
  }

  @Action
  [Actions.LOGOUT]() {
    this.context.commit(Mutations.PURGE_AUTH);
  }

  @Action
  async [Actions.REGISTER](credentials) {
    const params = {
      params: {
        ...credentials,
      },
    };
    const username = params.params.username as string;
    const firstName = params.params.firstName as string;
    const lastName = params.params.lastName as string;
    const password = params.params.password as string;
    const email = params.params.email as string;

    return Auth.signUp(username, password, email)
      .then((data) => {
        const UserInfo = API.graphql({
          query: createUserInfo,
          variables: {
            input: {
              firstName: firstName,
              lastName: lastName,
              role: "Admin",
              username: username,
              email: email,
            },
          },
        });
        console.log(UserInfo);
        console.log(data);
        this.context.commit(Mutations.SET_AUTH, data);
      })
      .catch(({ error }) => {
        console.log(error);
        //this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.FORGOT_PASSWORD]() {
    return ApiService.post("forgot_password", {})
      .then(() => {
        this.context.commit(Mutations.SET_ERROR, {});
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.VERIFY_AUTH]() {
    if (JwtService.getToken()) {
      return Auth.userSession(this.user)
        .then(() => {
          this.context.commit(Mutations.SET_AUTH, this.user);
        })
        .catch(() => {
          //this.context.commit(Mutations.SET_ERROR, response.data.errors);
          this.context.commit(Mutations.PURGE_AUTH);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
}
