import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { API } from "aws-amplify";
import { listMeasures } from "@/graphql/queries";
import {
  deleteMeasure,
  createMeasure,
  updateMeasure,
} from "@/graphql/mutations";

export interface Measures {
  data: unknown;
}

@Module
export default class MeasuresModule extends VuexModule implements Measures {
  data = [];

  /**
   * Get config from layout config
   * @returns {function(): *}
   */
  get measures() {
    return this.data;
  }

  @Action
  async [Actions.LIST_MEASURES]() {
    const response = await API.graphql({
      query: listMeasures,
    });
    this.context.commit(Mutations.SET_MEASURES, response);
  }
  @Mutation
  async [Mutations.SET_MEASURES](payload) {
    this.data = await payload.data.listMeasures.items;
  }
  @Mutation
  async [Mutations.UPDATE_MEASURE](payload) {
    //const cloud = payload.host;
    //if(cloud){
    //console.log("Cloud Storage");
    const data = await API.graphql({
      query: updateMeasure,
      variables: { input: payload },
    });
    console.log(data);
    //} else {
    //   console.log("Local Storage");
    // }
  }
  @Mutation
  async [Mutations.DELETE_MEASURE](payload) {
    //const cloud = payload.host;
    //if(cloud){
    //console.log("Cloud Storage");
    const data = await API.graphql({
      query: deleteMeasure,
      variables: { input: payload },
    });
    console.log(data);
    //} else {
    //   console.log("Local Storage");
    // }
  }
  @Mutation
  async [Mutations.CREATE_MEASURE](payload) {
    const name = payload.name;
    const description = payload.description;
    const shortId = payload.shortId;
    const cloud = payload.host;
    if (cloud) {
      console.log("Cloud Storage");
      const measure = { name, description, shortId };
      const data = await API.graphql({
        query: createMeasure,
        variables: { input: measure },
      });
      console.log(data);
    } else {
      console.log("Local Storage");
    }
  }
  @Action
  async [Actions.GET_MEASURES]() {
    return await this.data;
  }
}
