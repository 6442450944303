import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { API } from "aws-amplify";
import { listUnitConfigurations } from "@/graphql/queries";
import {
  deleteUnitConfiguration,
  createUnitConfiguration,
  updateUnitConfiguration,
} from "@/graphql/mutations";

export interface UnitConfigurations {
  data: unknown;
}

@Module
export default class UnitConfigurationsModule
  extends VuexModule
  implements UnitConfigurations
{
  data = [];

  /**
   * Get config from layout config
   * @returns {function(): *}
   */
  get unitConfigurations() {
    return this.data;
  }

  @Action
  async [Actions.LIST_UNIT_CONFIGURATIONS]() {
    const response = await API.graphql({
      query: listUnitConfigurations,
    });
    this.context.commit(Mutations.SET_UNIT_CONFIGURATIONS, response);
  }
  @Mutation
  async [Mutations.SET_UNIT_CONFIGURATIONS](payload) {
    this.data = await payload.data.listUnitConfigurations.items;
  }
  @Mutation
  async [Mutations.UPDATE_UNIT_CONFIGURATION](payload) {
    //const cloud = payload.host;
    //if(cloud){
    //console.log("Cloud Storage");
    const data = await API.graphql({
      query: updateUnitConfiguration,
      variables: { input: payload },
    });
    console.log(data);
    //} else {
    //   console.log("Local Storage");
    // }
  }
  @Mutation
  async [Mutations.DELETE_UNIT_CONFIGURATION](payload) {
    //const cloud = payload.host;
    //if(cloud){
    //console.log("Cloud Storage");
    const data = await API.graphql({
      query: deleteUnitConfiguration,
      variables: { input: payload },
    });
    console.log(data);
    //} else {
    //   console.log("Local Storage");
    // }
  }
  @Mutation
  async [Mutations.CREATE_UNIT_CONFIGURATION](payload) {
    const UnitId = payload.UnitId;
    const firmware = payload.firmware;
    const async = payload.async;
    const network = payload.network;
    const password = payload.password;
    const board = payload.board;
    const shield = payload.shield;
    const wifi = payload.wifi;
    const usb_port = payload.usb_port;
    const usb_baud_rate = payload.usb_baud_rate;
    const server_send = payload.server_send;
    const server_listen = payload.server_listen;
    const server_baud_rate = payload.server_baud_rate;
    const bluetooth = payload.bluetooth;
    const radio = payload.radio;
    const number_of_groups = payload.number_of_groups;
    const groups = payload.groups;
    //const zone = payload.zone;
    const cloud = true;
    if (cloud) {
      //console.log("Cloud Storage");
      //console.log(payload);
      const unitConfiguration = {
        UnitId,
        firmware,
        async,
        network,
        password,
        board,
        shield,
        wifi,
        usb_port,
        usb_baud_rate,
        server_listen,
        server_send,
        server_baud_rate,
        bluetooth,
        radio,
        number_of_groups,
        groups,
      };
      console.log(unitConfiguration);
      const data = await API.graphql({
        query: createUnitConfiguration,
        variables: { input: unitConfiguration },
      });
      console.log(data);
    } else {
      //console.log("Local Storage");
    }
  }
  @Action
  async [Actions.GET_UNIT_CONFIGURATIONS]() {
    return await this.data;
  }
}
