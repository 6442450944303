import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { API } from "aws-amplify";
import { listSensors } from "@/graphql/queries";
import { deleteSensor, createSensor, updateSensor } from "@/graphql/mutations";

export interface Sensors {
  data: unknown;
}

@Module
export default class SensorsModule extends VuexModule implements Sensors {
  data = [];

  /**
   * Get config from layout config
   * @returns {function(): *}
   */
  get sensors() {
    return this.data;
  }

  @Action
  async [Actions.LIST_SENSORS]() {
    const response = await API.graphql({
      query: listSensors,
    });
    this.context.commit(Mutations.SET_SENSORS, response);
  }
  @Mutation
  async [Mutations.SET_SENSORS](payload) {
    this.data = await payload.data.listSensors.items;
  }
  @Mutation
  async [Mutations.UPDATE_SENSOR](payload) {
    //const cloud = payload.host;
    //if(cloud){
    //console.log("Cloud Storage");
    const data = await API.graphql({
      query: updateSensor,
      variables: { input: payload },
    });
    console.log(data);
    //} else {
    //   console.log("Local Storage");
    // }
  }
  @Mutation
  async [Mutations.DELETE_SENSOR](payload) {
    //const cloud = payload.host;
    //if(cloud){
    //console.log("Cloud Storage");
    const data = await API.graphql({
      query: deleteSensor,
      variables: { input: payload },
    });
    console.log(data);
    //} else {
    //   console.log("Local Storage");
    // }
  }
  @Mutation
  async [Mutations.CREATE_SENSOR](payload) {
    const name = payload.name;
    const description = payload.description;
    const shortId = payload.shortId;
    const cloud = payload.host;
    if (cloud) {
      console.log("Cloud Storage");
      const sensor = { name, description, shortId };
      const data = await API.graphql({
        query: createSensor,
        variables: { input: sensor },
      });
      console.log(data);
    } else {
      console.log("Local Storage");
    }
  }
  @Action
  async [Actions.GET_SENSORS]() {
    return await this.data;
  }
}
